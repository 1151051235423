/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
//require('../css/global.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');


const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('bootstrap-notify');
// require('datatables.net');
// require('datatables.net-editor-bs4');
import * as bootstrapValidate from 'bootstrap-validate';
window.bootstrapValidate = bootstrapValidate;
//require('vue-hot-reload-api');
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
require('../Admin-template/js/addons/datatables');
require('../Admin-template/js/masked');




$(document).ready(function () {


    $("#sfd_telephone,#agent_telephone").mask("99999999");

    $('#rmEmail').hide();
    $('#registration_form_email').removeAttr('required');
    $('#registration_form_email').val('ccccc@cccc.bj');

    $('#role').change(function () {

        console.log($(this).val());
        if ($(this).val() == 1) {
            $('#rmSFD').show();
            $('#registration_form_sfd').attr('required', 'required');
            $('#rmEmail').hide();
            $('#registration_form_email').removeAttr('required');
            $('#registration_form_email').val('ccccc@cccc.bj');
        } else if ($(this).val() == 2) {

            $('#rmSFD').hide();
            $('#registration_form_sfd').removeAttr('required');
            $('#rmEmail').show();
            $('#registration_form_email').val('');
            $('#registration_form_email').attr('required', 'required');

        }
    });

    $('#myTableFilter').DataTable(
        {
            "iDisplayLength": 20,
            dom: 'Bfrtip',
            buttons: [
                //  'excelHtml5',
            ],
            "searching": true,
            "ordering": true,
            "fixedHeader": true,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/f2c75b7247b/i18n/French.json",
                "searchPlaceholder": "Entrer un mot clé"
            },
        }
    );

    if ($('#flashMessage').length) {
        var flashMes = $('#flashMessage').text();
        var label = $('#flashMessage').attr("class");

        switch (label) {
            case 'error':
                var color = 'danger';
                break;
            case 'succes':
                var color = 'success';
                break;
            default:
                var color = 'info';
        }

        $.notify({
            message: flashMes
        }, {
            type: color,
            placement: {
                from: 'top',
                align: 'center'
            }
        });
    }



    'use strict';
    window.addEventListener('load', function () {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);

    bootstrapValidate('#inputEmail', 'required:Veuillez entrer votre email');
    bootstrapValidate('#rechercheD', 'required:Veuillez entrer le nom');
    bootstrapValidate('#username', 'required:Veuillez entrer votre identifiant');
    bootstrapValidate('#password', 'required:Veuillez entrer votre mot de passe');

    $('[data-toggle="popover"]').popover();



});

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
